/* General body styles to keep the background and font consistent */
body {
    background-color: #f4f4f4;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

/* Main container that holds both details and additional info */
.main-container {
    position: relative; /* Establishes positioning context */
    display: flex; /* Ensures children are laid out in a line */
    justify-content: space-between; /* Spaces children evenly */
    max-width: 1200px; /* Adjust width as necessary */
    margin: 30px auto; /* Centering the main container */
}

.bio-image-container {
    display: flex;
    align-items: center;
}

/* Main container styling for the person details */
.person-details-container {
    width: 70%; /* Adjust width accordingly */
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
}

/* Styling for loading and error messages */
.loading, .error {
    text-align: center;
    font-size: 1.2rem;
}

/* Header styles for the name */
h1 {
    font-size: 2rem;
    color: #333333;
    text-align: center;
}

/* Styling for the image to make it fit nicely */
img {
    width: 300px; /* 300px for student-card, 225px for student-card-2 */
    height: 350px;
    display: block; /* Allows margin auto to center the image horizontally */
    margin: auto; /* Centers the image horizontally */
    object-fit: cover; /* Ensures the image covers the circular area without being distorted */
    padding: 25px; /* Adjust padding as needed */
}

/* Paragraph style for biography text */
.bio-container p {
    font-size: 1rem;
    color: #292828;
    line-height: 1.6;
}

.additional-info-container {
    position: absolute; /* Absolute relative to main-container */
    top: 0; /* Align to the top edge of main-container */
    right: -90px; /* Align to the right edge of main-container */
    width: 30%; /* Maintain consistent width */
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
}

.additional-info-container-2 {
    position: absolute; /* Absolute relative to main-container */
    right: -90px; /* Align to the right edge of main-container */
    top: 250px; /* Move the box 50px down from the top */
    width: 30%; /* Maintain consistent width */
    padding: 15px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    max-height: 300px; /* Set a maximum height */
    overflow: auto; 
}

.additional-info-container-2 p {
    color: #171717;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}

.linkedin-icon {
    font-size: 30px; /* Adjust size as needed */
}

.pdf-link-1, .external-link-1, .presentation-link-1, .link-button {
    background-color: #4285f4;
    color: white;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 3px 8px;
    font-size: 13px;
    text-decoration: none;
    border-radius: 3px;
    cursor: pointer;
}

.external-link-1 {
    background-color: #f4b400;
}

.presentation-link-1 {
    background-color: #00681d;
}

.pdf-link-1:hover, .external-link-1:hover, .presentation-link-1:hover, .link-button:hover, .all-presentation-link:hover {
    opacity: 0.8;
}

.additional-info-container-2.move-up {
    top: 275px; /* Move to the top */
  }


  /* General styles for mobile */
  @media (max-width: 600px) {
    body {
        background-color: #f4f4f4;
        font-family: 'Arial', sans-serif;
        margin: 0;
        padding: 0;
    }

    /* Main container should stack vertically on mobile */
    .main-container {
        flex-direction: column;
        max-width: 100%; /* Full width of the screen */
        margin: 0; /* Remove any margin for mobile */
        padding: 10px; /* Add some padding for better layout */
    }

    /* Person details container should take full width on mobile */
    .person-details-container {
        width: 100%; /* Full width */
        margin-bottom: 20px; /* Add space between elements */
        padding: 15px; /* Reduced padding for mobile */
    }

    /* Ensure flex-direction column for the bio container */
    .bio-container {
        display: flex;
        flex-direction: column;
        align-items: center; /* Center the content */
        padding: 10px;
    }

    /* Ensure image is centered and the bio comes under it */
    .bio-image-container {
        display: block; /* No flex needed for the image on mobile */
        text-align: center; /* Center image on mobile */
    }

    /* Header styles for mobile */
    h1 {
        font-size: 1.5rem; /* Slightly smaller font size */
        text-align: center;
    }

    /* Paragraph style for biography text */
    .bio-container p {
        width: 100%; /* Make it full width on mobile */
        text-align: center; /* Center text for better layout */
        font-size: 0.9rem; /* Slightly smaller font for mobile */
        line-height: 1.5;
        padding: 0 10px; /* Add padding for better readability */
    }

    /* Make the additional info container stack below the bio on mobile */
    .additional-info-container, .additional-info-container-2 {
        position: relative; /* Make it flow within the document */
        width: 100%; /* Full width */
        margin-top: 20px; /* Add space between elements */
        padding: 15px; /* Adjust padding for mobile */
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Reduce shadow intensity */
        right: 0; /* Remove the absolute positioning */
        top: auto; /* Remove the top positioning */
    }

    /* Adjust text in the additional info container */
    .additional-info-container-2 p {
        font-size: 12px; /* Slightly smaller text */
        line-height: 1.4;
    }

    /* Button and link styles for mobile */
    .pdf-link-1, .external-link-1, .presentation-link-1, .link-button {
        font-size: 12px; /* Slightly smaller text */
        padding: 5px 10px; /* Adjust padding for better touch area */
        margin: 5px 0; /* Add space between buttons */
    }

    .linkedin-icon {
        font-size: 24px; /* Reduce icon size */
    }

    /* Specific class adjustments for positioning */
    .additional-info-container-2.move-up {
        top: auto; /* Ensure no fixed positioning */
    }
}
