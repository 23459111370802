/* src/components/Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 900px;
    max-height: 100%;
    overflow-y: auto;
    position: relative;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .modal-content {
    margin-top: 40px; /* Adjust as necessary to avoid overlap with the close button */
  }

  @media (max-width: 600px) {
    .modal {
      max-width: 550px;
      max-height: 100%;
    }
  }

  