.people-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 30px; /* Add a top margin to push the cards down */
}

.student-card, .student-card-2 {
    width: 600px; /* Adjust for student-card-2 if needed */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.905);
    transition: transform 0.2s;
    text-decoration: none; /* Removes underline */
    color: inherit; /* Inherits text color from parent elements */
    cursor: pointer; /* Changes the cursor to indicate clickable */
    display: block; /* Makes the link take the shape of its container */
    border-radius: 5px;
}

.student-card:hover, .student-card-2:hover {
    transform: scale(1.05);
    background: #f9f9f9; /* Optional: change on hover */
}

.student-image, .student-image-2 {
    width: 300px; /* 300px for student-card, 225px for student-card-2 */
    height: 300px; /* Same as width to maintain aspect ratio */
    display: block; /* Allows margin auto to center the image horizontally */
    margin: auto; /* Centers the image horizontally */
    object-fit: cover; /* Ensures the image covers the circular area without being distorted */
    padding: 25px; /* Adjust padding as needed */
}

.student-info, .student-info-2 {
    padding: 40px; /* Adjust padding for student-info-2 as needed */
}

h2 {
    text-align: center;
    padding: 25px;
    font-size: 36px;
}

.people-container-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 30px; /* Consistent with other containers */
}

.student-card-2 {
    width: 450px; /* Smaller width for different card style */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.905);
    transition: transform 0.2s;
    margin-bottom: 30px; /* Adds bottom margin to each card */
}

.student-image-2 {
    width: 225px; /* Adjusted width for smaller card */
    height: 225px; /* Maintains aspect ratio */
    display: block; /* Allows margin auto to center the image horizontally */
    margin: auto; /* Centers the image horizontally */
    object-fit: cover; /* Ensures the image covers the circular area without being distorted */
    padding: 10px; /* Reduced padding */
}

.student-info-2 {
    padding: 25px; /* Adjust padding */
}

.student-card a, .student-card-2 a {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Ensures the text color is the same as the parent element */
}

.student-card a:visited, .student-card-2 a:visited {
    color: inherit; /* Prevents color change after the link is visited */
}

.btn--primary-1 {
    background-color: var(--primary); /* Ensure this variable is defined */
    color: #242424;
    border: 1px solid #242424;
    padding: 10px 30px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-decoration: none; /* Removes underline from links */
    display: inline-block; /* Ensures padding affects layout */
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.btn--primary-1:last-child {
    margin-right: 0; /* Removes margin from the last button to align it properly */
}

.btn--primary-1:hover {
    background-color: #242424; /* Changes background to dark on hover */
    color: white; /* Changes text color to white on hover */
}


.button-container {
    text-align: right; /* Aligns buttons to the right */
    padding: 20px; /* Adds some spacing around the buttons */
    margin-top: 20px; /* Adds margin above the button container */
}

/* General Styles for Mobile */
@media (max-width: 600px) {
    body, html {
        background-color: white;
        margin: 0;
        padding: 0;
        overflow-x: hidden; /* Prevent horizontal scrolling */
    }

    .people-container, .people-container-2 {
        flex-direction: column; /* Stack cards vertically */
        align-items: center; /* Center the cards */
        margin-top: 20px;
        padding: 0 10px; /* Add some padding on the sides */
    }

    .student-card, .student-card-2 {
        width: 80%; /* Full width of the screen */
        max-width: 90%; /* Prevent overflow */
        margin-bottom: 20px; /* Add spacing between cards */
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Reduce shadow for mobile */
    }

    .student-image, .student-image-2 {
        padding: 10px; /* Reduced padding for smaller screens */
        max-width: 100%;
        object-fit: cover;
    }

    .student-info, .student-info-2 {
        padding: 20px; /* Reduce padding for smaller screens */
        text-align: center; /* Center-align text for mobile */
    }

    h2 {
        font-size: 28px; /* Reduce font size on mobile */
        padding: 15px;
    }

    .btn--primary-1 {
        padding: 20px; /* Smaller buttons on mobile */
        font-size: 16px;
        width: 40%; /* Adjust button text size */
        margin-left: 5px;
    }

    .button-container {
        text-align: center; /* Center the buttons on mobile */
        padding: 15px;
    }
}

