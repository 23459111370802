html, body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

.publications-container {
  padding: 10px 20px;
}

.year-section h2 {
  font-size: 30px;
  margin-right: 110px;
}

.publication-item {
  margin-bottom: 30px; /* Increased margin for better spacing */
  list-style-type: none;
}

.publication-title {
  font-size: 20px;
  margin-bottom: 1px; /* Space between title and citation */
}

.publication-citation {
  display: block;
  font-size: 16px;
  margin-bottom: 10px; /* Space between citation and links */
}

.links {
  display: flex;
  gap: 10px; /* Space between links */
}

.pdf-link, .external-link, .presentation-link, .biblink-button {
  background-color: #4285f4;
  color: white; 
  padding: 5px 8px;
  text-decoration: none;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: -16px;
}

.external-link {
  background-color: #f4b400;
}

.presentation-link {
  background-color:  #00681d;
}

.biblink-button {
  background-color: #3708f3;
}

.pdf-link:hover, .external-link:hover, .presentation-link:hover, .biblink-button:hover,.all-presentation-link:hover  {
  opacity: 0.8;
}

.all-presentation-link {
  background-color:  #00681d;
  color: white; 
  padding: 10px 18px;
  font-size: 18px;
  text-decoration: none;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  float: right; /* Add this line */
  margin-left: auto; /* Ensures it stays on the right */
}

.additional-info-container-2.move-up {
  margin-top: -275px; /* Adjust this value as needed */
}

@media (max-width: 600px) {

  .publications-container {
      padding: 5px 10px; /* Reduced padding for narrower screens */
  }

  .year-section h2 {
      font-size: 24px; /* Smaller font size for headers */
  }

  .publication-item {
      margin-bottom: 30px; /* Slightly reduced margin */
  }

  .publication-title {
      font-size: 16px; /* Smaller font size for titles */
  }

  .publication-citation {
      font-size: 10px; /* Smaller font size for citations */
  }

  .additional-info-container-2.move-up {
      margin-top: 0px; /* Adjust or remove negative margin for mobile */
  }

  .all-presentation-link {
    padding: 8px 5px;
    font-size: 16px;
  }

}

