body, html {
    background-color: white;
    margin: 0;
    padding: 0;
}

.news-header-container {
    position: relative;
    text-align: center;
}

.news-header {
    width: 100%;
    height: auto;
    display: block;
    padding: 0;
}

.news-title-details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #002E5D;
    font-size: 54px;
}

.news-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.news-items {
    width: 90%;
    padding: 20px;
    box-sizing: border-box;
}

.news-item-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0;
    margin-bottom: 40px;
    width: 100%;
}

.news-details-image {
    width: 90%;
    max-width: 400px;
    height: auto;
    display: block;
    margin: 10px 0;
}

.news-info {
    margin-top: 20px;
    width: 80%;
}

.news-details-title {
    font-size: 24px;
    color: #003366;
    text-align: center;
}

.news-details-date {
    color: #474747;
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.news-details-info {
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 40px;
    line-height: 1.8;
    text-align: left;
}

.back-button {
    background-color: #00b2b2;
    color: white;
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 20px;
    text-decoration: none;
}

.back-button:hover {
    background-color: #007a7a;
}

@media (min-width: 600px) {
    .news-title-details {
        font-size: 54px;
        top: 70%;
        left: 18%;
    }

    .news-details-title {
        font-size: 36px;
        margin-top: -30px;
    }

    .news-details-info {
        font-size: 18px;
        line-height: 2.4;
    }

    .back-button {
        font-size: 18px;
    } 

    .news-item-details {
        flex-direction: row;
        align-items: flex-start;
    }

    .news-info {
        text-align: left;
        margin-left: 10px;
    }

    .news-details-date {
        color: #474747;
        font-size: 17px;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .news-details-image {
        width: 100%;
        max-width: 400px;
        height: 450px;
        display: block;
        margin: 10px 0;
    }
}
