.hero-container {
  background-image: url('../../public/images/PotentialBack.jpg');
  height: 100vh; 
  width: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  background-size: cover; 
  background-position: center; 
}

  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  :root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
    text-decoration: none;
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3 ease-out;
    text-decoration: none;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3 ease-out;
}

@media (max-width: 600px) {
  /* Hero container adjustments for mobile */
  .hero-container {
    height: 100vh; /* Full screen height */
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    background-size: cover; 
    background-position: center; 
    padding: 0 20px; /* Add padding for better spacing */
  }

  /* Adjust hero container header for mobile */
  .hero-container > h1 {
    color: #fff;
    font-size: 70px; /* Smaller font for mobile */
    margin-top: -120px; /* Reduce margin for better spacing */
    text-align: center; /* Center text */
  }

  /* Adjust hero container paragraph for mobile */
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 18px; /* Smaller font for mobile */
    text-align: center; /* Center text */
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  /* Adjust button container for mobile */
  .hero-btns {
    margin-top: 20px; /* Reduce margin for mobile */
    text-align: center; /* Center buttons */
  }

  /* Adjust buttons for mobile */
  .hero-btns .btn {
    margin: 6px;
    width: 100%; /* Full width on mobile for better touch targets */
    padding: 10px 20px; /* Adjust padding */
  }

  /* Adjust the play icon margin for mobile */
  .fa-play-circle {
    margin-left: 2px; /* Reduce margin for mobile */
  }

  /* Root variable remains unchanged */
  :root {
    --primary: #fff;
  }

  /* Button styling adjustments for mobile */
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    text-decoration: none;
    width: 100%; /* Full width for mobile buttons */
  }

  /* Primary button adjustments for mobile */
  .btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
    text-decoration: none;
    width: 100%; /* Full width */
  }

  /* Outline button adjustments for mobile */
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3 ease-out;
    text-decoration: none;
    width: 100%; /* Full width */
  }

  /* Medium button adjustments for mobile */
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px; /* Slightly smaller font */
  }

  /* Large button adjustments for mobile */
  .btn--large {
    padding: 10px 24px; /* Adjust padding for mobile */
    font-size: 18px; /* Slightly smaller font */
  }

  /* Hover effect for buttons on mobile */
  .btn--medium:hover, .btn--large:hover {
    background: #fff;
    color: #242424;
    transition: all 0.3 ease-out;
  }
}


