.navbar {
    background: #002E5D;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    position: relative;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 15px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0; /* Adjust this value as needed */
  }

  .navbar-logo2 {
    color: #fff;
    justify-self: start;
    margin-left: 15px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    position: absolute;
    left: 200px; /* Adjust this value as needed */
  }

  .navbar-logo img {
    width: 200px;  /* Adjust width as needed */
    height: auto;  /* Maintain aspect ratio */
}

.navbar-logo2 img {
  width: 230px;  /* Adjust width as needed */
  height: auto;  /* Maintain aspect ratio */
}
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: -35rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #fff;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 62px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #002E5D;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      height: 380px;
      border-radius: 20px;
    }
  
    .nav-links {
      text-align: center;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar {
      display: flex;
      align-items: center; /* This will vertically center-align the logos if needed */
      position: relative; /* Positioning context for the navbar */
      padding-left: 25px; /* Add padding to offset the entire navbar content */
    }
    
    .navbar-logo, .navbar-logo2 {
      cursor: pointer;
      text-decoration: none;
      font-size: 2rem;
      color: #fff;
    }
    
    .navbar-logo2 {
      margin-left: 20px; /* Adjust this value to manage spacing between logos */
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }

    .navbar-logo2 img {
      margin-left: -75px;
      width: 175px;
    }

    .navbar-logo img {
      width: 175px;
    }
    
  }