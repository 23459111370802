.lab-container {
    max-width: 1800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    margin-bottom: 40px;
}

.lab-content {
    display: flex;
    align-items: flex-start;
}

.lab-content p {
    font-size: 24px;
}

.lab-title {
    font-size: 40px;
    margin-bottom: 30px;
}

.lab-image {
    width: 450px; /* Adjust the width to make it more aligned to the left */
    height: 450px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 10px;
    margin-bottom: -55px;
}

.lab-description {
    margin-top: 20px;
    flex: 1;
    font-size: 20px;
    line-height: 1.6;
    color: #000000;
}

@media (max-width: 600px) {
    /* Lab container adjustments for mobile */
    .lab-container {
        max-width: 100%; /* Full width on mobile */
        margin: 0 auto;
        padding: 10px; /* Reduce padding for mobile */
        font-family: Arial, sans-serif;
        margin-bottom: 20px; /* Reduce bottom margin */
    }

    .lab-content {
        display: flex;
        flex-wrap: wrap; /* Ensure elements wrap to the next line */
    }

    /* Title adjustments for mobile */
    .lab-title {
        font-size: 24px; /* Reduce font size for better readability */
        margin-bottom: 20px; /* Adjust margin for spacing */
        text-align: center; /* Center title on mobile */
        width: 100%;
    }

    /* Paragraph font size adjustment */
    .lab-content p {
        font-size: 18px; /* Reduce font size for mobile */
    }

    /* Image adjustments for mobile */
    .lab-image {
        width: 100%; /* Make image responsive */
        max-height: 300px; /* Maintain aspect ratio */
        margin-right: 10%; /* Remove right margin */
        margin-bottom: 10px; /* Adjust bottom margin */
        border-radius: 10px; /* Keep border radius */
    }

    /* Lab description adjustments for mobile */
    .lab-description {
        margin-top: 10px; /* Reduce top margin */
        font-size: 16px; /* Reduce font size */
        line-height: 1.4; /* Adjust line height */
        color: #000000;
        text-align: center; /* Center text for better readability */
    }
}
