body, html {
    background-color: white;
    margin: 0;
    padding: 0;
}

.news-header-container {
    position: relative;
    text-align: center;
}

.news-header {
    width: 100%;
    height: auto;
    max-height: 240px; /* Adjust this value to set the desired height */
    display: block;
    padding: 0;
}

.news-title {
    position: absolute;
    top: 70%;
    left: 18%;
    transform: translate(-50%, -50%);
    color: #002E5D;
    font-size: 54px;
}

.news-content {
    display: flex;
}

.news-items {
    width: 80%;
    padding: 0px 40px;
    border-right: 2px solid #ccc;
    border-left: 6px solid #002E5D;
    border-top: 3px solid #002E5D;
    margin-left: 0px;
}

.news-item {
    display: flex;
    border-bottom: 2px solid #ccc;
    padding: 10px 10px;
}

.news-image {
    width: 100%;
    max-width: 400px;
    height: 450px;
    display: block;
    margin: 10px 0;
}

.news-info {
    margin-left: 20px;
    flex-direction: column;
    justify-content: center;
}

.news-heading {
    font-size: 1.8em;
    color: #003366;
}

.news-date {
    color: #474747;
    font-size: 17px;
    margin-bottom: 10px;
}

.news-information {
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
    line-height: 2.0;
}

.view-button {
    background-color: #00b2b2;
    color: white;
    padding: 8px 15px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 20px;
    margin-top: 40px;
}

.view-button:hover {
    background-color: #007a7a;
}

.pagination {
    display: flex;
    justify-content: center; /* Center the pagination items */
    align-items: center;
    padding: 40px;
    position: relative; /* Allow absolute positioning within this container */
}

.page-item-number, .next-page-button {
    font-size: 18px;
    padding: 8px 15px;
    border: 2px solid #002E5D;
    background-color: white;
    border-radius: 8px;
    margin: 3px; /* Add some margin between buttons */
}

.page-item-number:hover, .next-page-button:hover {
    transform: scale(1.1); /* Enlarge the button */
    transition: transform 0.2s ease-in-out;
}

.LinkedIn-Content {
    padding: 30px;
    display: flex;
    justify-content: center; /* This centers the entire block horizontally */
    gap: 20px; /* Adjusts the space between each LinkedIn post */
}

.LinkedIn-Post {
    height: 400px; 
    width: 500px;
    border: 2px solid black; /* Bolder and darker border */
    border-radius: 10px;
}

.See-More-Button {
    text-decoration: none;
    font-size: 20px;
    padding: 0px 5px;
    border-radius: 25px;
    background-color: #003366;
    color: white;
    margin-top: 170px;
    height: 0%;
    margin-left: 20px;
    transition: transform 0.3s ease;
}

.See-More-Button:hover {
    transform: scale(1.1);
    background-color: #007a7a;
}


@media (max-width: 600px) {
    body, html {
        background-color: white;
        margin: 0;
        padding: 0;
        overflow-x: hidden; /* Prevent horizontal scroll */
        overflow-y: auto;   /* Ensure vertical scroll */
        height: 100%;       /* Ensure body takes full height */
    }

    .news-header-container {
        position: relative;
        text-align: center;
    }

    .news-header {
        width: 100%;
        height: auto;
        padding: 0;
    }

    .news-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #002E5D;
        font-size: 35px; /* Reduced font size for smaller screens */
    }

    .news-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto; /* Ensure the content can scroll if needed */
        padding-bottom: 20px; /* Add padding for better spacing */
    }

    .news-items {
        width: 100%;
        padding: 20px;
        border-right: none;
        border-left: none;
        border-top: none; /* Remove borders that clutter the layout */
        overflow-y: auto; /* Allow scrolling inside this section */
    }

    .news-item {
        flex-direction: column;
        align-items: center;
        border-bottom: 3px solid #ccc;
        margin-bottom: 10px; /* Add spacing between news items */
    }

    .news-image {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
        max-width: 360px; /* Limit maximum width */
    }

    .news-info {
        margin: 20px 0;
        flex-direction: left;
        text-align: left;
        width: 100%;
    }

    .news-heading {
        font-size: 26px; /* Smaller font size */
        margin-bottom: 20px;
        width: 280px;
        margin-left: -30px; /* Align left */
    }

    .news-date {
        font-size: 14px; /* Smaller font size */
        margin-left: 60px;
    }

    .view-button, .page-item-number, .next-page-button {
        font-size: 16px; /* Smaller font size for buttons */
        padding: 8px 12px; /* Smaller padding */
    }

    .LinkedIn-Content {
        display: none; /* Hide LinkedIn content on mobile */
    }

    .news-information {
        font-size: 15px;
        line-height: 2.0;
        width: 280px;
        align-items: left;
        margin-left: -20px;
    }
}
