.footer-container {
    background-color: #777;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: white;
}

.footer-links {
    display: flex;
    justify-content: space-around;
    width: 100%; /* Updated to use the full width */
}

.footer-link-items,
.contact-link-items {
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensure items are centered */
    margin-bottom: 16px;
    font-size: 16px;
    width: 33%; /* Give equal width for proper alignment */
}

.footer-link-items a,
.contact-link-items p {
    color: white;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a {
    text-decoration: underline;
}

.footer-link-items h2,
.contact-link-items h2 {
    margin-bottom: 8px;
}

.footer-link-items a:hover {
    color: #e9e9e9;
}

.footer-main-image {
    display: flex;
    justify-content: center; /* Center the image */
}

.footer-main-image img {
    height: auto;
    width: 100%; /* Make the image responsive */
    max-width: 700px; /* Limit maximum width */
    padding: 50px 0px;
}

.website-rights {
    font-size: 14px;
    text-align: center; /* Center copyright text */
    width: 100%;
}

@media screen and (max-width: 820px) {
    .footer-links {
        flex-direction: column;
    }

    .footer-link-items,
    .contact-link-items {
        width: 100%; /* Full width on smaller screens */
    }

    .footer-main-image img {
        margin-top: 16px; /* Add some spacing */
    }
}
