.cards {
    padding: 4rem;
    background: #fff;
    text-align: center;
  }

  .main-title{
    font-size: 30px;
    padding: 60px 150px; /* Adjust padding for inner spacing */
    line-height: 50px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.905);
    border-radius: 20px;
    font-weight: 50;
    width: 80%; /* Set width as a percentage of the parent element, or use a fixed value like 600px */
    height: auto; /* Adjust height if needed, or use a fixed value */
    margin: 0 auto; /* Center the element horizontally */
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1350px;
    width: 90%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 350px;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 24px;
    line-height: 24px;
  }
  
  
  .cards__items:first-child .cards__item__pic-wrap {
    padding-top: 25%;
}
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  

  @media (max-width: 600px) {

    aside, .cards__item, .cards__item__info, .cards__container {
    position: relative; /* Ensure proper positioning */
    height: 100%; /* Make sure the height takes up the full parent container */
    }

    .cards {
      padding: 2rem; /* Reduce padding for mobile */
      background: #fff;
      text-align: center;
    }
  
    /* Adjust the main title for mobile */
    .main-title {
      font-size: 18px; /* Reduce font size for mobile */
      padding: 20px 20px; /* Reduce padding for better spacing */
      line-height: 24px; /* Adjust line height for mobile */
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Reduce shadow intensity for mobile */
      border-radius: 10px; /* Reduce border radius */
      width: 100%; /* Set width to fit mobile screens */
      margin: 0 auto; /* Center the title */
      text-align: left;
    }
  
    /* Cards container adjustments */
    .cards__container {
      display: flex;
      flex-flow: column;
      align-items: center;
      max-width: 100%; /* Full width on mobile */
      width: 100%;
      margin: 0 auto;
    }
  
    /* Adjust wrapper margins for mobile */
    .cards__wrapper {
      position: relative;
      margin: 30px 0 30px; /* Reduce margins for better spacing on mobile */
    }
  
    /* Card item adjustments for mobile */
    .cards__items {
      margin-bottom: 20px;
    }
  
    .cards__item {
      display: block; /* Stack cards vertically on mobile */
      margin: 0 0 20px 0; /* Adjust margins to stack items */
      border-radius: 10px; /* Reduce border radius */
    }
  
    /* Card link adjustments */
    .cards__item__link {
      width: 100%; /* Full width for mobile */
      box-shadow: 0 4px 15px rgba(56, 125, 255, 0.17); /* Slightly lighter shadow */
      border-radius: 10px; /* Adjust border radius */
    }
  
    /* Adjust image wrapper for mobile */
    .cards__item__pic-wrap {
      padding-top: 50%; /* Adjust padding for image aspect ratio on mobile */
      overflow: hidden;
    }
  
    /* Image adjustments */
    .cards__item__img {
      height: auto; /* Adjust height to auto for better scaling */
      max-height: 250px; /* Reduce max height for smaller screens */
      object-fit: cover;
    }
  
    /* Card info container adjustments */
    .cards__item__info {
      padding: 15px 20px; /* Reduce padding for mobile */
    }
  
    /* Text adjustments for card content */
    .cards__item__text {
      color: #252e48;
      font-size: 18px; /* Reduce font size for mobile */
      line-height: 22px; /* Adjust line height for readability */
    }
  
    /* First card image padding adjustments */
    .cards__items:first-child .cards__item__pic-wrap {
      padding-top: 35%; /* Adjust padding for the first item */
    }
    
    .cards__item__info {
      animation: none;
      transition: none;
  }

  .cards__item__info {
    opacity: 1;
}

  
  
    
  }
  