.image-container {
    display: flex;
    width: 100%;
}

.full-width-image {
    flex: 1;
    width: 25%;
    height: 200px;
    padding: 0;
}

.Projects {
    margin-top: -80px;
    margin-bottom: 40px;
    font-size: 40px;
    text-align: center;
    color: black;
}

.projects-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.project-item {
    margin: 6px;
    border: 2px solid #5e5d5d;
    border-radius: 8px;
    flex: 0 1 calc(32% - 15px); /* Ensures four items per row */
    max-width: 700px; /* Ensures the items don't grow too large */
    text-align: center; /* Center the content of project items */
    position: relative;
}

.project-status-label {
    border: 2px solid #000000;
    position: absolute;
    padding: 2px 5px;
    top: 3px;
    right: 3px;
    border-radius: 7px;
    background-color: green;
    color: aliceblue;
}

.btn--primary-2-Project:hover {
    background-color: green; /* Changes background to dark on hover */
    color: white; /* Changes text color to white on hover */
}

.btn--primary-2-Project {
    background-color: var(--primary); /* Ensure this variable is defined */
    color: #242424;
    border: 1px solid #242424;
    padding: 10px 30px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-decoration: none; /* Removes underline from links */
    display: inline-block; /* Ensures padding affects layout */
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.btn--primary-1-Project {
  background-color: var(--primary); /* Ensure this variable is defined */
  color: #242424;
  border: 1px solid #242424;
  padding: 10px 30px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none; /* Removes underline from links */
  display: inline-block; /* Ensures padding affects layout */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.btn--primary-1-Project:hover {
  background-color: #242424; /* Changes background to dark on hover */
  color: white; /* Changes text color to white on hover */
}

.project-image {
    width: 350px;
    height: 260px;
}

.project-item h2 {
    font-size: 28px;
    padding: 8px;
    margin-top: 20px;
}

.project-item p {
    margin: 5px 10px;
    text-align: left;
}

.publication-details-2 h3 {
    font-size: 15px;
    padding: 2px 10px;
    text-align: left;
}

.publication-item-2 {
    margin-bottom: 5px
}

.publication-list-2 {
    list-style: none;
    padding: 0;
    max-height: 145px; /* Adjust this height as needed */
    overflow-y: scroll; /* Enables vertical scrolling and keeps the scrollbar always visible */
}

.pdf-link-2, .external-link-2, .presentation-link-2, .link-button-2 {
    background-color: #4285f4;
    color: white;
    margin-left: 10px;
    padding: 3px 8px;
    font-size: 13px;
    text-decoration: none;
    border-radius: 3px;
    cursor: pointer;
}

.external-link-2 {
    background-color: #f4b400;
}

.presentation-link-2 {
    background-color: #00681d;
}

.pdf-link-2:hover, .external-link-2:hover, .presentation-link-2:hover, .link-button-2:hover {
    opacity: 0.8;
}

@media (max-width: 600px) {
    /* Ensure the image container fits the mobile screen */
    .image-container {
      display: none;
    }

    /* Adjust images to be full-width on mobile */
    .full-width-image {
      width: 100%; /* Full width of the container */
      height: auto; /* Maintain aspect ratio */
      padding: 0;
    }
  
    /* Project title adjustments for mobile */
    .Projects {
      margin-top: -40px; /* Adjust margins for better spacing */
      margin-bottom: 20px; /* Reduce bottom margin */
      font-size: 30px; /* Smaller font size for mobile */
      text-align: center;
      color: black;
    }
  
    /* Adjust project container for mobile */
    .projects-container {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  
    /* Project item adjustments for mobile to fit two per row */
    .project-item {
      flex: 0 1 calc(95% - 10px); /* Adjust width to fit two items per row */
      max-width: 100%; /* Allow project items to be responsive */
      margin: 5px; /* Reduce margin for better spacing */
      text-align: center;
      border: 2px solid #5e5d5d;
      border-radius: 8px;
      position: relative;
    }
  
    /* Project image adjustments for mobile */
    .project-image {
      width: 350px; /* Full width of the container */
      height: 300px; /* Maintain aspect ratio */
    }
  
    /* Project title adjustments */
    .project-item h2 {
      font-size: 20px; /* Reduce font size for mobile */
      padding: 5px;
      margin-top: 10px; /* Adjust top margin */
    }
  
    /* Project description adjustments */
    .project-item p {
      margin: 5px 10px;
      font-size: 14px; /* Reduce font size for readability on mobile */
      text-align: left;
    }
  
    /* Project status label adjustments */
    .project-status-label {
      display: none;
    }
  
    /* Button adjustments for mobile */
    .btn--primary-2-Project {
      display: none;
    }

    .btn--primary-1-Project {
      display: none;
    }
  
    /* Publication details adjustments */
    .publication-details-2 h3 {
      font-size: 13px; /* Reduce font size */
      padding: 2px 10px;
      text-align: left;
    }
  
    /* Publication list adjustments */
    .publication-list-2 {
      max-height: 100px; /* Reduce max height for smaller screens */
      overflow-y: scroll;
    }
  
    /* Button hover effects remain unchanged */
    .pdf-link-2, .external-link-2, .presentation-link-2, .link-button-2 {
      background-color: #4285f4;
      color: white;
      margin-left: 5px; /* Reduce margin for mobile */
      padding: 3px 6px; /* Adjust padding */
      font-size: 12px; /* Reduce font size */
      border-radius: 3px;
      cursor: pointer;
    }
  
    .external-link-2 {
      background-color: #f4b400;
    }
  
    .presentation-link-2 {
      background-color: #00681d;
    }
  
    /* Hover effect remains the same */
    .pdf-link-2:hover, .external-link-2:hover, .presentation-link-2:hover, .link-button-2:hover {
      opacity: 0.8;
    }
  }
  